<div class="header">
    <div class="header-left">

        <button *ngIf="location != '/home' && location != '/'" class="nav-button back-button"
            (click)="appService.goBack()">
            <i class='bx bx-chevron-left'></i>
        </button>

        <!--MENU-->
        <div class="nav-bar hideMenuHeaderDesktop">
            <span *ngFor="let c of appService.categoriePuntiDiInteresse;">
                <a *ngIf="c.inDrawer && c.pubblicata" routerLink="/categoria/{{c.id}}" class="nav-item pointer"
                    [class.nav-item-active]="location === '/categoria/' + c.id">
                    {{c.nome}}
                </a>
            </span>
        </div>

    </div>

    <div class="header-logo">
        <a routerLink="/">
            <img src="assets/img/logo.png" alt="Logo">
        </a>
    </div>

    <div class="header-right">

        <!--MENU-->
        <div class="nav-bar hideMenuHeaderDesktop">
            <a (click)="appService.viewProfile()" class="nav-item pointer"
                [class.nav-item-active]="location === '/profile' || location === '/login' || location === '/register' || location === '/recupera-password'">
                <i class='bx bx-user'></i> {{'HEADER.PROFILE'| translate}}
            </a>
            <a (click)="appService.viewScanner()" class="nav-item pointer"
                [class.nav-item-active]="location === '/scan'">
                <i class='bx bx-scan'></i> {{'HEADER.SCAN'| translate}}
            </a>
            <a (click)="appService.viewHome()" class="nav-item pointer"
                [class.nav-item-active]="location === '/home' || location === '/'">
                <i class='bx bx-map'></i> {{'HEADER.SEARCH'| translate}}
            </a>

            <a (click)="appService.changeLang()" class="nav-item pointer">
                <img src="assets/img/lang/{{appService.actualLang}}.png" class="flag-icon">
                {{ appService.actualLang | uppercase }}
            </a>
        </div>


        <!--SHARE MENU-->
        <div class="dropdown" *ngIf="
            location !== '/' && 
            location !== '/home' && 
            location !== '/login' && 
            location !== '/register' && 
            location !== '/profile' && 
            location !== '/recupera-password' && 
            location !== '/step1' && 
            location !== '/step2' && 
            location !== '/step3' 
            ">
            <button class="nav-button menu-button" (click)="toggleShareMenu()"><i class='bx bx-share-alt'></i></button>
            <div class="dropdown-content">
                <a href="https://www.facebook.com/sharer.php?t={{'SHARE.SHARE_TEXT_TITLE' |translate}}{{location}}"
                    class="pointer" target="_blank">
                    <i class='bx bxl-facebook'></i> Facebook
                </a>
                <a href="https://api.whatsapp.com/send?text={{'SHARE.SHARE_TEXT_TITLE' |translate}}{{location}}"
                    class="pointer" target="_blank">
                    <i class='bx bxl-whatsapp'></i> whatsapp
                </a>
            </div>
        </div>

    </div>
</div>