import { Injectable } from '@angular/core';
import { User } from './interfaces';

@Injectable()
export class UserAuth {

    public userData: User;

    constructor() {

    }

    setUser(loginData: User) {
        console.log('loginData:', loginData);
        this.userData = loginData;
        localStorage.setItem('user', JSON.stringify(loginData));
    }

    setUpdatedData(data: User) {
        this.userData.nome = data.nome;
        this.userData.cognome = data.cognome;
        this.userData.email = data.email;
        this.userData.cellulare = data.cellulare;
        localStorage.setItem('user', JSON.stringify(this.userData));
    }

    logOut() {
        this.userData = undefined;
        localStorage.removeItem('user');
    }

    getLoginData(): any | null {
        return new Promise<any>((resolve, reject) => {
            if(this.isLogged()) { 
                resolve(this.userData)
                return
            }
            const loginDataString = localStorage.getItem('user');
            if (loginDataString) {
                resolve(this.setUser(JSON.parse(loginDataString)));
            } else {
                resolve(null);
            }
        });
    }

    isLogged() {
        return this.userData ? true : false;
      }
}