import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  public loginForm: FormGroup;
  passwordInput:any = document.getElementById('password');
  toggleIcon:any = document.querySelector('.bx-show');
  public error: string;
  public sending: boolean = false;

  constructor(private formBuilder: FormBuilder, public appService: AppService) { 
    this.buildForm();
  }

  ngOnInit(): void {
  }

  // Funzione per mostrare/nascondere la password
  togglePasswordVisibility() {
    if (this.passwordInput.type === "password") {
      this.passwordInput.type = "text";
      this.toggleIcon.classList.replace('bx-show', 'bx-hide');
    } else {
      this.passwordInput.type = "password";
      this.toggleIcon.classList.replace('bx-hide', 'bx-show');
    }
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(128),
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,4})$')
      ])],
      password: ['', Validators.required]
    });
  }
  
  login() {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    console.log('login');
    if (this.loginForm.valid) {
      const email = this.loginForm.controls['email'].value;
      const password = this.loginForm.controls['password'].value;

      this.error = '';
      this.sending = true;
      this.appService.apiService.authLogin({email, password}).then((data) => {
        console.log('login successful: ', data);
        this.appService.userAuth.setUser(data as User);
        this.appService.viewHome();
      }).catch((err) => {
        this.error = err;
        console.log('login failed: ', err);
      }).finally(() => {
        this.sending = false;
      });
    }
  }
}
