import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public form: FormGroup;
  public submitted: boolean = false;
  public sending: boolean = false;
  public error: string;

  constructor(private formBuilder: FormBuilder, public appService: AppService) { 
    this.buildForm();
  }

  ngOnInit(): void {
  }

  buildForm() {
    this.form = this.formBuilder.group({
      nome: ['', Validators.compose([Validators.required,
        Validators.maxLength(128)
      ])],
      cognome: ['', Validators.compose([Validators.required,
        Validators.maxLength(128)
      ])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(128),
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,4})$')
      ])],
      password: ['', Validators.compose([ 
        Validators.required,
        Validators.minLength(6)
      ])],
      confirmPassword: ['', Validators.compose([ 
        Validators.required,
        Validators.minLength(6)
      ])],
      cellulare: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10,}$')
      ])],
      privacy: [false, Validators.required]
    });
  }

  signup() {
    // this.appService.show('TEST')
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    this.submitted = true;
    console.log('signup privacy ' + this.form.controls['privacy'].value);
    if (this.form.valid) {
      const nome = this.form.controls['nome'].value;
      const cognome = this.form.controls['cognome'].value;
      const email = this.form.controls['email'].value;
      const password = this.form.controls['password'].value;
      const confirmPassword = this.form.controls['confirmPassword'].value;
      const cellulare = this.form.controls['cellulare'].value + '';

      const privacy = this.form.controls['privacy'].value;

      console.log('nome: ' + nome + ' cognome' + cognome + ' email: ' + email + ' password: ' + password + ' confirm password: ' + confirmPassword + ' cellulare: ' + cellulare + ' privacy: ' + privacy)
      if(password !== confirmPassword) {
        alert(this.appService.translate.instant('PAGES.SIGNUP.ERRORS.PASSWORD'));
        return false;
      }

      this.sending = true;
      this.error = '';
      this.appService.apiService.authSignup({nome, cognome, email, password, cellulare}).then((data) => {
        console.log('signup successful: ', data);
        this.form.reset();
        this.appService.viewLogin();
      }).catch((err) => {
        console.log('signup failed: ', err);
        this.error = err;
      }).finally(() => {
        this.submitted = false;
        this.sending = false;
      });
    }
  }
}
