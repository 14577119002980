<!--PAGE-->
<section class="margin-content">
    <!--CONTAINER-->
    <div class="container-animated">

        <!--EDIT ACCOUNT-->
        <h2 class="sub-header-title">
            {{'GENERAL_ACCOUNT.PROFILE' | translate}}
            <br>
            <span (click)="appService.logOut()" style="font-size: 0.5em;">{{'GENERAL_ACCOUNT.LOGOUT' |
                translate}}</span>
        </h2>

        <form *ngIf="form" [formGroup]="form" class="form margin-bottom50" (ngSubmit)="update()"
            (keyup.enter)="update()">

            <label for="nome">{{'GENERAL_ACCOUNT.NOME' | translate}}:</label>
            <input type="text" formControlName="nome" required
                [class]="(!form.controls.nome.pristine && !form.controls.nome.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.nome.hasError('maxlength')" class="form-error-msg">
                {{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>

            <label for="cognome">{{'GENERAL_ACCOUNT.COGNOME' | translate}}:</label>
            <input type="text" formControlName="cognome" required
                [class]="(!form.controls.cognome.pristine && !form.controls.cognome.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.cognome.hasError('maxlength')" class="form-error-msg">
                {{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>

            <label for="email">{{'GENERAL_ACCOUNT.EMAIL' | translate}}:</label>
            <input type="email" id="email" formControlName="email" required
                [class]="(!form.controls.email.pristine && !form.controls.email.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.email.hasError('maxlength')" class="form-error-msg">
                {{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>
            <span *ngIf="form.controls.email.hasError('pattern')" class="form-error-msg">
                {{'GENERAL_ACCOUNT.ERRORS.EMAIL_NOT_VALID' | translate}}</span>

            <label for="cellulare">{{'GENERAL_ACCOUNT.TEL' | translate}}:</label>
            <input type="number" formControlName="cellulare" required
                [class]="(!form.controls.cellulare.pristine && !form.controls.cellulare.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.cellulare.hasError('pattern')" class="form-error-msg">
                {{'GENERAL_ACCOUNT.ERRORS.TEL_ERR' | translate}}
            </span>

            <button type="submit" [disabled]="sending">{{ ((sending ? 'GENERAL.LOADING' : 'GENERAL.BTN_SAVE') |
                translate) | capitalize }}</button>
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>
        </form>

    </div>
</section>