import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Step1Component } from './components/pages/tutorial/step1/step1.component';
import { Step2Component } from './components/pages/tutorial/step2/step2.component';
import { Step3Component } from './components/pages/tutorial/step3/step3.component';
import { AppService } from './providers/AppService';
import { ApiService } from './providers/ApiService';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionService } from './providers/Connection';
import { UserAuth } from './models/UserAuth';
import { AuthGuard } from './providers/AuthGuard';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ItinerarioComponent } from './components/pages/itineraro/itinerario.component';
import { HomeComponent } from './components/pages/home/home.component';
import { CategoriaComponent } from './components/pages/categoria/categoria.component';
import { OpereComponent } from './components/pages/opere/opere.component';
import { OperaComponent } from './components/pages/opera/opera.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { UppercasePipe } from './pipes/uppercase.pipe';
import { LowercasePipe } from './pipes/lowercase.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RicercaComponent } from './components/pages/ricerca/ricerca.component';
import { ArticoliComponent } from './components/pages/articoli/articoli.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { MiradorComponent } from './components/pages/mirador/mirador.component';
import { ItinerariComponent } from './components/pages/itinerari/itinerari.component';
import { ArticoloComponent } from './components/pages/articolo/articolo.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ScanComponent } from './components/pages/scan/scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ErrorComponent,
    LoginComponent,
    RegisterComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ProfileComponent,
    ItinerarioComponent,
    HomeComponent,
    CategoriaComponent,
    OpereComponent,
    OperaComponent,
    CapitalizePipe,
    UppercasePipe,
    LowercasePipe,
    RicercaComponent,
    ArticoliComponent,
    RecoverPasswordComponent,
    HeaderComponent,
    MiradorComponent,
    ItinerariComponent,
    ArticoloComponent,
    ScanComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxTypedJsModule,
    HttpClientModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ZXingScannerModule
  ],
  providers: [AppService, ApiService, ConnectionService, UserAuth, AuthGuard, MatSnackBar],
  bootstrap: [AppComponent]
})
export class AppModule { }
