<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--CONTAINER-->
    <div class="container-animated">

        <!--SEARCH-->
        <h2 class="sub-header-title">
            {{ opera ? opera.title : ''}}
        </h2>

        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

            <!--OPERA-->
            <div *ngIf="opera">
                <div class="row">

                    <!--CARD-->
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="card">
                            <div class="card-image" *ngIf="opera.digital_object">
                                <img [src]="opera.digital_object.url">
                            </div>
                        </div>
                        <div align="center">
                            <button *ngIf="opera" (click)="appService.viewMirador(opera.iiif_manifest)">{{
                                ('PAGES.OPERA.VIEW_MIRADOR' |
                                translate) | capitalize }}</button>
                        </div>

                        <div *ngIf="appService.userAuth.isLogged()" align="center">

                            <br>
                            <h3>{{'PAGES.OPERA.ESPORTA'| translate}}</h3>

                            <div *ngIf="opera.dublinCore">
                                <a [href]="opera.dublinCore" target="_blank">
                                    <i class="bx bx-upload"></i> Dublin Core 1.1 XML </a>
                            </div>
                            <div *ngIf="opera.ead">
                                <a [href]="opera.ead" target="_blank">
                                    <i class="bx bx-upload"></i> EAD 2002 XML </a>
                            </div>
                            <div *ngIf="opera.iccd">
                                <a [href]="opera.iccd" target="_blank">
                                    <i class="bx bx-upload"></i> ICCD 2022 XML </a>
                            </div>
                            <div *ngIf="opera.raws">
                                <a [href]="opera.raws" target="_blank">
                                    <i class="bx bx-download"></i> {{ 'PAGES.OPERA.DWNLOAD_ZIP' | translate }} </a>
                            </div>
                            <br>
                            <button style="background: #efbc74!important;" *ngIf="opera" (click)="downloadOpera()"
                                [disabled]="downloading">{{
                                ((downloading ? 'PAGES.OPERA.DWNLOADING' : 'PAGES.OPERA.DWNLOAD_PDF') | translate) |
                                capitalize }}</button>
                        </div>
                    </div>

                    <!--CONTENUTO-->
                    <div class="col-lg-8 col-md-8 col-sm-12">

                        <!--AREA 1-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA1_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA1_ITEM_1' | translate}}</span> {{ opera.tsk }}
                            </p>
                        </div>

                        <!--AREA 2-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA2_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA2_ITEM_1' | translate}}</span> {{ opera.nctr
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA2_ITEM_2' | translate}}</span> {{ opera.esc }}
                            </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA2_ITEM_3' | translate}}</span> {{ opera.ecp }}
                            </p>
                        </div>

                        <!--AREA 3-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA3_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 4-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA4_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA4_ITEM_1' | translate}}</span> {{ opera.sgti
                                }} </p>
                        </div>

                        <!--AREA 5-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA5_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 6-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA6_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA6_ITEM_1' | translate}}</span> {{ opera.pvcs
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA6_ITEM_2' | translate}}</span> {{ opera.pvcr
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA6_ITEM_3' | translate}}</span> {{ opera.pvcp
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA6_ITEM_4' | translate}}</span> {{ opera.pvcc
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA6_ITEM_5' | translate}}</span> {{ opera.ldc }}
                            </p>
                        </div>

                        <!--AREA 7-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA7_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 8-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA8_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA8_ITEM_1' | translate}}</span> {{ opera.dtzg
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA8_ITEM_2' | translate}}</span> {{ opera.dtzs
                                }} </p>
                        </div>

                        <!--AREA 9-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA9_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 10-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA10_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 11-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA11_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA11_ITEM_1' | translate}}</span> {{ opera.auts
                                }} </p>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA11_ITEM_2' | translate}}</span> {{ opera.autm
                                }} </p>
                        </div>

                        <!--AREA 12-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA12_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA12_ITEM_1' | translate}}</span> {{ opera.mtc
                                }} </p>
                        </div>

                        <!--AREA 13-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA13_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 14-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA14_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 15-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA15_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA15_ITEM_1' | translate}}</span> {{ opera.stcc
                                }} </p>
                        </div>

                        <!--AREA 16-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA16_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 17-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA17_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA17_ITEM_1' | translate}}</span> {{ opera.desi
                                }} </p>
                        </div>

                        <!--AREA 18-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA18_TITLE' | translate}}</h2>
                        </div>

                        <!--AREA 19-->
                        <div class="record-details">
                            <h2>{{'PAGES.OPERA.LIST.AREA19_TITLE' | translate}}</h2>
                            <p><span class="bold">{{'PAGES.OPERA.LIST.AREA19_ITEM_1' | translate}}</span> {{ opera.cdgg
                                }}
                            </p>
                        </div>

                        <!--AREA 20-->
                        <div class="record-details" *ngIf="appService.userAuth.isLogged()">
                            <h2>{{'PAGES.OPERA.LIST.AREA20_TITLE' | translate}}</h2>
                            <p>
                                <span class="bold">{{'PAGES.OPERA.LIST.AREA20_ITEM_1' | translate}}</span>
                                <a style="word-wrap: break-word;" href="{{opera.iiif_manifest}}">
                                    {{opera.iiif_manifest}}</a>
                            </p>
                        </div>

                    </div>

                </div>

            </div>

            <!--NOT FOUND-->
            <div class="error-404-area" *ngIf="!loading && !opera">
                <div class=" d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{ 'PAGES.OPERA.ERRORS.OPERA_NOT_FOUND' | translate }}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br><br><br>
    </div>
</section>