<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--EDIT ACCOUNT-->
    <h2 class="sub-header-title"><i class='bx bx-pin'></i> {{'PAGES.ITINERARIO.ITINERARIO'| translate}}</h2>
    <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>
    <ul *ngIf="!loading && itinerario" class="">

        <li style="padding: 5px;">
            <i class='bx bx-pin {{icons[0]}}'></i> {{ itinerario.titolo }}
        </li>
    </ul>
    <ul *ngIf="!loading && itinerario">
        <li style="padding: 5px;">
            <p>{{ itinerario.descrizione }}</p>
        </li>

        <!--<li *ngFor="let i of itinerari; index as index">
            <i class='bx bx-pin {{icons[index]}}'></i> {{ i.titolo }}
        </li>-->
        <!--<li>
            <i class='bx bx-pin color-giallo'></i> Chiese
        </li>
        <li>
            <i class='bx bx-pin color-verde'></i> Siti Archeologici
        </li>
        <li>
            <i class='bx bx-pin color-blu'></i> Piazze
        </li>
        <li>
            <i class='bx bx-pin color-viola'></i> Monumenti
        </li>-->
    </ul>

    <!--NOT FOUND-->
    <div class="error-404-area" *ngIf="!loading && !itinerario">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="notfound">
                        <div class="notfound-bg">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <h1>Oops!</h1>
                        <h3>{{'PAGES.ITINERARIO.ERRORS.NOT_FOUND' | translate}}</h3>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--CONTAINER-->
    <div class="container-animated" *ngIf="!loading && itinerario">
        <div class="row">
            <div class="map-container">
                <div class="map-frame">
                    <div id="mappa"></div>
                </div>
            </div>
        </div>
    </div>

    <!--CONTAINER-->
    <div class="container-animated" *ngIf="!loading && itinerario">
        <div class="row">

            <!--CARD 1-->
            <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let p of itinerario.puntointeresses"
                [style]="p.pubblicato ? '' : 'display: none'">
                <div class="card">
                    <div class="card-image">
                        <img [src]="p.pathCopertina" [alt]="p.titolo">
                    </div>
                    <div class="card-content">
                        <div class="card-title">
                            <div>{{ p.titolo }}</div>
                        </div>
                        <a *ngIf="p.telefono" href="tel:{{p.telefono}}"><i class='bx bx-phone'></i></a>
                        <a *ngIf="p.email" href="mailto:{{p.email}}"><i class='bx bx-envelope'></i></a>
                        <button routerLink="/opere/{{p.id}}">{{ ('GENERAL.VIEW' | translate) | uppercase}}</button>
                    </div>
                </div>
            </div>



        </div>
    </div>

</section>
