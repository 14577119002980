import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FullOperaAtom } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-opera',
  templateUrl: './opera.component.html',
  styleUrls: ['./opera.component.scss']
})
export class OperaComponent {

  public opera: FullOperaAtom;
  public error: string;
  public loading: boolean = true;
  private slug: string;
  public downloading: boolean = false;
  public downloadingXML: boolean = false;

  constructor(public appService: AppService, private actRoute: ActivatedRoute) {
    this.slug = decodeURIComponent(this.actRoute.snapshot.params.slug);
    this.getOperaBySlyg();
  }

  getOperaBySlyg() {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if(!this.slug) this.error = this.appService.translate.instant('PAGES.OPERA.ERRORS.SLUG_NOT_VALID');

    this.opera = null;
    this.error = '';

    this.appService.apiService.getOperaAtom(this.slug).then((data: FullOperaAtom) => {
      this.opera = data;
      console.log('data: ', data);
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.loading = false);
  }
    
  downloadOpera() {
    if(this.downloading) return;
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if(!this.slug) this.error = this.appService.translate.instant('PAGES.OPERA.ERRORS.SLUG_NOT_VALID');
    this.downloading = true;
    this.error = '';

    this.appService.apiService.dwnloadOperaAtom(this.slug).then((data: any) => {
      console.log('data: ', data);
      const blob = new Blob([data], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.slug + '.pdf';
      link.click();
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.downloading = false);
  }
}
