<!--HEADER-->
<div class="header-tutorial">
    <img src="assets/img/logo.png" alt="image">
    <br>
    <h2>{{'PAGES.HOME.HERO_TITLE'| translate}}</h2>

    <br>
    <img src="assets/img/tutorial2.png" class="tutorialIcon">
    <br>
    <h2>{{'PAGES.TUTORIAL.CONSULTAGLIORARI'| translate}}</h2>
</div>

<!--PAGE-->
<section class="tutorialSfondo">

    <!--CONTAINER-->
    <div class="container-animated" style="margin-top: 40px;" align="center">

        <a routerLink="/step3"><button type="submit" style="min-width: 200px;">{{'PAGES.TUTORIAL.CONTINUA'|
                translate}}</button></a>
        <br>
        <br>
        <a routerLink="/home">{{'PAGES.TUTORIAL.SALTA'| translate}}</a>

    </div>

    <!--LOGHI-->
<!--    <div class="image-row" style="position: fixed; left: 0; bottom: 30px;">-->
<!--        <div class="image-column margin-auto">-->
<!--            <img src="assets/img/sfondocomune.png" alt="Comune">-->
<!--        </div>-->
<!--        <div class="image-column margin-auto">-->
<!--            <img src="assets/img/sfondoassociazione.png" alt="Associazione">-->
<!--        </div>-->
<!--    </div>-->

</section>
