import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/pages/login/login.component';
import {RegisterComponent} from './components/pages/register/register.component';
import {Step1Component} from './components/pages/tutorial/step1/step1.component';
import {Step3Component} from './components/pages/tutorial/step3/step3.component';
import {Step2Component} from './components/pages/tutorial/step2/step2.component';
import {IsLoggedAuthGuard, IsNotLoggedAuthGuard, IsPublicPageAuthGuard} from './providers/AuthGuard';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ItinerarioComponent} from './components/pages/itineraro/itinerario.component';
import {HomeComponent} from './components/pages/home/home.component';
import {CategoriaComponent} from './components/pages/categoria/categoria.component';
import {OpereComponent} from './components/pages/opere/opere.component';
import {OperaComponent} from './components/pages/opera/opera.component';
import {RicercaComponent} from './components/pages/ricerca/ricerca.component';
import {ArticoliComponent} from './components/pages/articoli/articoli.component';
import {RecoverPasswordComponent} from './components/pages/recover-password/recover-password.component';
import {MiradorComponent} from './components/pages/mirador/mirador.component';
import {ItinerariComponent} from './components/pages/itinerari/itinerari.component';
import {ArticoloComponent} from './components/pages/articolo/articolo.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {ScanComponent} from './components/pages/scan/scan.component';

const routes: Routes = [
    {path: '', component: HomeComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'login', component: LoginComponent, canActivate: [IsNotLoggedAuthGuard]},
    {path: 'register', component: RegisterComponent, canActivate: [IsNotLoggedAuthGuard]},
    {path: 'recupera-password', component: RecoverPasswordComponent, canActivate: [IsNotLoggedAuthGuard]},
    {path: 'profile', component: ProfileComponent, canActivate: [IsLoggedAuthGuard]},
    {path: 'step1', component: Step1Component, canActivate: [IsPublicPageAuthGuard]},
    {path: 'step2', component: Step2Component, canActivate: [IsPublicPageAuthGuard]},
    {path: 'step3', component: Step3Component, canActivate: [IsPublicPageAuthGuard]},
    {path: 'itinerari', component: ItinerariComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'itinerario/:id', component: ItinerarioComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'categoria/:id', component: CategoriaComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'opere/:id', component: OpereComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'opera/:slug', component: OperaComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'mirador', component: MiradorComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'home', component: HomeComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'scan', component: ScanComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'articoli/:id', component: ArticoliComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'articolo/:id', component: ArticoloComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: 'ricerca/:title', component: RicercaComponent, canActivate: [IsPublicPageAuthGuard]},
    {path: '**', component: ErrorComponent, canActivate: [IsPublicPageAuthGuard]}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
