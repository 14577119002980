<!--PAGE-->
<section class=" margin-content">

    <!--CONTAINER-->
    <div class="container-animated">

        <h2 class="sub-header-title">{{ ('PAGES.LOGIN.BTN_LOGN' | translate) | uppercase }}</h2>
        <form *ngIf="loginForm" [formGroup]="loginForm" class="form" (ngSubmit)="login()" (keyup.enter)="login()">
            <label for="email">{{ ('PAGES.LOGIN.EMAIL' | translate) | uppercase }}</label>
            <input type="email" id="email" formControlName="email" placeholder="user@gmail.com">
            <span *ngIf="loginForm.controls.email.hasError('pattern')" class="form-error-msg">Email non valida</span>

            <label for="password">{{ ('PAGES.LOGIN.PASSWORD' | translate) | uppercase }}</label>
            <input type="password" id="password" formControlName="password" placeholder="********">

            <button type="submit" [disabled]="sending">{{ ((sending ? 'GENERAL.LOADING' : 'PAGES.LOGIN.BTN_LOGN') | translate) | capitalize }}</button>
            <span *ngIf="error" class="form-error-msg"><br>{{ 'ERRORS_API.' + error | translate}}</span>
            <br>
            <button (click) = "appService.viewSignup()">{{ ('PAGES.LOGIN.BTN_SIGNUP' | translate) | capitalize }}</button>

            <a routerLink="/recupera-password" class="forgot-password">{{ 'PAGES.LOGIN.FORGOT_PASSWORD' | translate }}</a>
        </form>
        
    </div>

</section>