import { Injectable } from "@angular/core";
import { ApiService } from "./ApiService";
import { ConnectionService } from "./Connection";
import { UserAuth } from "../models/UserAuth";
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { OwaService } from "../../providers/owa-service";
import { CategoriePuntiDiInteresse } from "../models/interfaces";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AppService {

    //ACTUAL LANG SESSION
    public actualLang: string;
    public actualLangImg: string;

    public categoriePuntiDiInteresse: CategoriePuntiDiInteresse[] = [];
    public errorCategory: string;

    private _lang: BehaviorSubject<string>;
    lang$: Observable<string>;
    
    constructor(
        public apiService: ApiService,
        public connection: ConnectionService,
        public userAuth: UserAuth,
        public router: Router,
        private location: Location,
        public translate: TranslateService,
        private snackBar: MatSnackBar
    ) {
        this._lang = new BehaviorSubject<string>(this.actualLang);
        this.lang$ = this._lang.asObservable();
    }

    start(): Promise<boolean> {
        return new Promise<any>(async (resolve, reject) => {
            this.setTranslate(this.getTranslate());
            await this.getCategoriePuntiDiInteresse();
            this.userAuth.getLoginData().then(() => {
                resolve(true);
            }).catch(() => {
                resolve(true);
            });
        });
    }

    //SET TRANSLATE
    setTranslate(val?: string) {
        console.log('val: ', val);
        if (val) {
            val = this.acceptedLanguage(val);
            this.actualLang = val;
            // moment.locale(val);
            this.translate.use(val);
        } else {
            console.log('this.translate.getBrowserLang(): ', this.translate.getBrowserLang());
            this.actualLang = this.acceptedLanguage(this.translate.getBrowserLang());
        }

        //UPD STORAGE
        localStorage.setItem('actualLang', this.actualLang);

        //UPD DEFAULT
        this.translate.setDefaultLang(this.actualLang);

        // moment.locale(this.actualLang);

        //UPD IMG LANG
        this.actualLangImg = "assets/img/lang/" + this.actualLang + ".png";

        this._lang.next(this.actualLang);
        //SET ALL
        this.translate.use(this.actualLang);
    }

    getTranslate(): string {
        return localStorage.getItem('actualLang')
    }

    changeLang() {
        this.actualLang == 'it' ? this.setTranslate('en') : this.setTranslate('it')
    }

    acceptedLanguage(val: string) {
        switch (val) {
            case 'en':
            case 'it':
            case 'es':
                break;
            default:
                val = 'it'
        }

        return val;
    }

    show(message: any, duration: number = 2000): void {
        this.snackBar.open(this.translate.instant(message), 'Close', {
            duration,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        });
    }

    goBack() {
        this.location.back();
    }

    viewLogin() {
        this.router.navigate(['/login']);
    }

    viewHome() {
        this.router.navigate(['/']);
    }

    viewScanner() {
        this.router.navigate(['/scan']);
    }

    viewTutorial() {
        this.router.navigate(['/step1']);
    }

    viewSignup() {
        this.router.navigate(['/register']);
    }

    viewProfile() {
        this.router.navigate(['/profile']);
    }

    viewSearch(text: string) {
        this.router.navigate(['/ricerca/' + encodeURIComponent(text)]);
    }

    viewMirador(link: string) {
        this.router.navigate(['/mirador'], {
            state: {
                link
            }
        });
    }

    viewArticolo(id: any) {
        this.router.navigate(['/articolo/' + id]);
    }

    logOut() {
        this.userAuth.logOut();
        this.viewLogin();
    }

    trackOWAevent() {
        console.log('trackOWAevent: ');
        OwaService.emitEvent(['trackAction', 'test', 'testInvio', 'inviato test']);
    }

    getCategoriePuntiDiInteresse() {
        return new Promise((resolve, reject) => {
            if(this.categoriePuntiDiInteresse?.length > 0) {
                resolve(true);
                return;
            }
            if (!this.connection.getConnection()) {
                this.errorCategory = this.connection.noConnection(this);
                reject(this.errorCategory);
                return;
            }
            this.errorCategory = '';
            this.categoriePuntiDiInteresse = [];
            this.apiService.getCategoriePuntiDiInteresse().then((data: CategoriePuntiDiInteresse[]) => {
                console.log(data);
                this.categoriePuntiDiInteresse = data;
                resolve(data);
            }).catch((err) => {
                this.errorCategory = err
                console.log('error: ', err);
                reject(err);
            });
        });
      }
}