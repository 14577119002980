<!--PAGE-->
<section class=" margin-content">

    <!--CONTAINER-->
    <div class="container-animated">

        <h2 class="sub-header-title">{{ ('PAGES.SIGNUP.TITLE' | translate) | uppercase }}</h2>
        <form *ngIf="form" [formGroup]="form" class="form margin-bottom50" (ngSubmit)="signup()"
            (keyup.enter)="signup()">

            <label for="nome">{{'GENERAL_ACCOUNT.NOME' | translate}}:</label>
            <input type="text" formControlName="nome" required
                [class]="(!form.controls.nome.pristine && !form.controls.nome.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.nome.hasError('maxlength')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>

            <label for="cognome">{{'GENERAL_ACCOUNT.COGNOME' | translate}}:</label>
            <input type="text" formControlName="cognome" required
                [class]="(!form.controls.cognome.pristine && !form.controls.cognome.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.cognome.hasError('maxlength')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>

            <label for="email">{{'GENERAL_ACCOUNT.EMAIL' | translate}}:</label>
            <input type="email" id="email" formControlName="email" required
                [class]="(!form.controls.email.pristine && !form.controls.email.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.email.hasError('maxlength')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.MAX_LENGTH' | translate}}</span>
            <span *ngIf="form.controls.email.hasError('pattern')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.EMAIL_NOT_VALID' | translate}}</span>

            <label for="password">{{'GENERAL_ACCOUNT.PASS' | translate}}:</label>
            <input type="password" id="password" formControlName="password" required
                [class]="(!form.controls.password.pristine && !form.controls.password.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.password.hasError('minlength')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.MIN_LENGTH_PASS' | translate}}</span>

            <label for="confirm-password">{{'GENERAL_ACCOUNT.REP_PASS' | translate}}:</label>
            <input type="password" id="confirm-password" formControlName="confirmPassword" required
                [class]="(!form.controls.confirmPassword.pristine && !form.controls.confirmPassword.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.confirmPassword.hasError('minlength')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.MIN_LENGTH_PASS' | translate}}</span>
            <span
                *ngIf="form.controls.password.value && form.controls.confirmPassword.value && form.controls.password.value !== form.controls.confirmPassword.value"
                class="form-error-msg">{{ 'PAGES.SIGNUP.ERRORS.PASSWORD' | translate }}</span>

            <label for="cellulare">{{'GENERAL_ACCOUNT.TEL' | translate}}:</label>
            <input type="number" formControlName="cellulare" required
                [class]="(!form.controls.cellulare.pristine && !form.controls.cellulare.valid) ? 'invalidInput' : ''">
            <span *ngIf="form.controls.cellulare.hasError('pattern')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.PATTERN_TEL' | translate}}</span>

            <label>
                <input type="checkbox" formControlName="privacy" name="accept-terms" id="accept-terms" required
                    [class]="form.controls.privacy.value || !submitted ? '' : 'invalidInput'">
                {{'GENERAL_ACCOUNT.PRIVACY_ACCEPT' | translate}}
            </label>

            <button type="submit" [disabled]="sending">{{ ((sending ? 'GENERAL.LOADING' : 'PAGES.SIGNUP.BTN_SIGNUP') |
                translate) | capitalize }}</button>
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>
            <a (click)="appService.viewLogin()" class="forgot-password">{{'GENERAL_ACCOUNT.ERRORS.NO_REG_LOG' |
                translate}}</a>

        </form>

    </div>
</section>