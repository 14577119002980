import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {

  public form: FormGroup;
  public error: string;
  public sending: boolean = false;
  public success: string;

  constructor(private formBuilder: FormBuilder, public appService: AppService) { 
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(128),
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,4})$')
      ])]
    });
  }
  
  passwordRecover() {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    console.log('recover-pass');
    if (this.form.valid) {
      const email = this.form.controls['email'].value;

      this.error = '';
      this.success = '';
      this.sending = true;
      this.appService.apiService.recoverPassword(email).then((data) => {
        console.log('recover successful: ', data);
        this.success = 'PAGES.RECOVER_PASSWORD.LINK_RESET_SEND';
      }).catch((err) => {
        this.error = err;
        console.log('recover failed: ', err);
      }).finally(() => {
        this.sending = false;
      });
    }
  }
}
