<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--EDIT ACCOUNT-->
    <h2 class="sub-header-title"><i class='bx bx-time'></i> {{ articolo ? articolo.titolo : 'NOT FOUND' }}</h2>

    <!--CONTAINER-->
    <div class="container-animated" *ngIf="articolo">
        <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

        <!-- Slider di Immagini -->
        <div class="image-slider" *ngIf="articolo.mediaarticolos.length > 0">
            <img [src]="articolo.mediaarticolos[activeImageIndex].path" alt="Slide image"
                (click)="openFullscreenGallery(articolo.mediaarticolos)">
            <button class="prev" (click)="changeImage(-1, articolo)">&#10094;</button>
            <button class="next" (click)="changeImage(1, articolo)">&#10095;</button>
            <div class="dots">
                <span *ngFor="let image of articolo.mediaarticolos; let i = index" (click)="setActiveImage(i, articolo)"
                    [class.dot-active]="i === activeImageIndex"></span>
            </div>
        </div>

        <!--COVER-->
        <img width="100%" *ngIf="articolo.mediaarticolos.length < 1" [src]="articolo.pathCopertina"
            [alt]="articolo.titolo">

        <!-- Gallery Fullscreen -->
        <div class="fullscreen-gallery" *ngIf="showFullscreenGallery">
            <img [src]="fullscreenImages[activeFullscreenImageIndex].path" alt="Fullscreen image">
            <button class="close" (click)="showFullscreenGallery = false">&times;</button>
            <button class="prev" (click)="changeFullscreenImage(-1)">&#10094;</button>
            <button class="next" (click)="changeFullscreenImage(1)">&#10095;</button>
            <div class="dots">
                <span *ngFor="let image of fullscreenImages; let i = index" (click)="setActiveFullscreenImage(i)"
                    [class.dot-active]="i === activeFullscreenImageIndex"></span>
            </div>
        </div>


        <!--TITLE & DOWNLOAD-->
        <div class="card-content" *ngFor="let art of articolo.allegatoarticolos">
            <div class="card-title"> {{ art.titolo }}</div>
            <span *ngIf="art.errorDownload"
                class="form-error-msg"><br>{{art.errorDownload}}</span>
            <button (click)="downloadFile(art)">{{ 'PAGES.ARTICOLI.DOWNLOAD_FILE' |
                translate }}</button>
        </div>

        <!--INFO-->
        <div style="padding: 20px;">
            <a href="mailto:{{articolo.email}}" target="_blank"><i class='bx bx-envelope'></i> {{articolo.email}}</a>
            <br>
            <a href="tel:{{articolo.telefono}}" target="_blank"><i class='bx bx-phone'></i> {{articolo.telefono}}</a>
            <br>
            <span><i class='bx bx-pin'></i> {{articolo.indirizzo}}</span>

            <div class="card" style="padding: 15px;margin: 20px 0px 0px 0px;width: 100%;"
                [innerHTML]="articolo.contenuto">
            </div>

        </div>

    </div>
</section>