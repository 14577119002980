import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { PuntoDiInteresse } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';
import { HttpClient } from '@angular/common/http';
import { mergeMap, toArray } from 'rxjs/operators';


@Component({
  selector: 'app-opere',
  templateUrl: './opere.component.html',
  styleUrls: ['./opere.component.scss']
})
export class OpereComponent {

  //TRADUZIONE
  textToTranslate = '';
  translatedText = '';
  spinnerTranslate: boolean;
  private apiUrl = 'https://libretranslate.com/translate';
  private apiKey = '75eb6dee-827d-45d1-af6b-e528eef130b1';


  public error: string;
  public loading: boolean = true;
  public puntoDiInteresse: PuntoDiInteresse;

  constructor(public appService: AppService, private actRoute: ActivatedRoute, public http: HttpClient) {
    const id = decodeURIComponent(this.actRoute.snapshot.params.id);
    this.getPuntoDiInteresse(id);

    this.appService.lang$.subscribe(
      lang => {
        console.log('lang changed: ', lang);
        this.startTranslate(lang);
      }
    );
  }

  startTranslate(actualLang: string) {

    if (!this.puntoDiInteresse) {
      return false;
    }

    let from = "";
    let to = "";

    if (actualLang === 'it') {
      this.translatedText = this.puntoDiInteresse.contenuto;
      return false;
    } else if (actualLang === 'en') {
      from = "it";
      to = "en";
    }

    this.spinnerTranslate = true;
    let text = this.stripHtml(this.puntoDiInteresse.contenuto);
    this.translateText(text, from, to).subscribe({
      next: translatedText => {
        this.spinnerTranslate = false;
        this.translatedText = translatedText;
      },
      error: error => {
        this.spinnerTranslate = false;
        this.translatedText = ''

        console.error("Errore di traduzione:", error);
      }
    });

  }

  translateText(text: string, fromLang: string, toLang: string): Observable<string> {
    const segments = this.splitText(text, 1000);

    return from(segments).pipe(
      mergeMap(segment => this.translateSegment(segment, fromLang, toLang), null, 1),
      toArray(),
      mergeMap(translatedSegments => from([translatedSegments.join('')]))
    );
  }

  private splitText(text: string, maxLength: number): string[] {
    const result = [];
    while (text.length > 0) {
      let segment = text.substring(0, maxLength);
      let nextIndex = maxLength;

      if (text.length > maxLength && text[nextIndex] !== ' ') {
        const lastSpaceIndex = segment.lastIndexOf(' ');
        if (lastSpaceIndex > 0) {
          segment = segment.substring(0, lastSpaceIndex);
          nextIndex = lastSpaceIndex;
        }
      }

      result.push(segment);
      text = text.substring(nextIndex).trim();
    }
    return result;
  }

  private translateSegment(segment: string, fromLang: string, toLang: string,): Observable<string> {
    return this.http.post<any>(this.apiUrl, { q: segment, source: fromLang, target: toLang, api_key: this.apiKey })
      .pipe(
        mergeMap(response => from([response.translatedText]))
      );
  }

  stripHtml(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  convertToPercentage(value: number): number {
    if (value < -180 || value > 180) {
      throw new Error('Value out of range. It should be between -180 and 180.');
    }
    return (value + 180) / 360 * 100;
  }


  getPuntoDiInteresse(id: string) {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if (!id) this.error = this.appService.translate.instant('PAGES.CATEGORY.ERRORS.ID_NOT_VALID');

    this.puntoDiInteresse = null;
    this.error = '';
    this.appService.apiService.getDettagliPuntoDiInteresse(Number(id)).then((data: PuntoDiInteresse) => {
      if (data?.pubblicato) {
        this.puntoDiInteresse = data;

        //TRADUCI
        if (this.appService.actualLang === 'en') {
          this.startTranslate(this.appService.actualLang);
        }
        else {
          this.translatedText = this.puntoDiInteresse.contenuto;
        }

      }
      console.log('data: ', data);
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.loading = false);
  }
}
