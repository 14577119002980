import { Component, OnInit } from '@angular/core';
import { ResponseChat } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  //VARIABILI CHATBOT 
  isChatOpen = false;
  messages = [];
  userInput = '';

  public searchText: string;
  public error: string;

  //PWA
  installEvent: any;
  isInStandaloneMode: boolean = false;
  public installed: Subject<any> = new Subject();
  InstalledStatus: any = {
    INSTALLED: 'appinstalled',
    BEFORE_INSTALL: 'beforeinstallprompt'
  };
  hideBanner: boolean = false;

  constructor(public appService: AppService, public router: Router) {

    this.subscribeInstalledStatus();
    this.isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
    console.log("PWA " + this.isInStandaloneMode);

  }

  subscribeInstalledStatus() {
    console.log("PWA subscribeInstalledStatus");
    window.addEventListener(this.InstalledStatus.BEFORE_INSTALL, event => {
      console.log("PWA subscribeInstalledStatus_done");
      this.installEvent = event;
      this.installed.next(event);
    });
  }

  install() {
    console.log("PWA install");
    this.installEvent.prompt();
  }


  search(): void {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if (this.searchText == null || this.searchText == undefined) return;
    if (this.searchText.trim() == '') return;
    this.appService.viewSearch(this.searchText);
  }

  toggleChat() {
    this.isChatOpen = !this.isChatOpen;
  }

  sendMessage() {
    if (this.userInput.trim()) {
      if (this.messages.length > 0) {
        const lastItem = this.messages[this.messages.length - 1];
        if (lastItem?.isError) this.messages.pop();
        if (lastItem?.isLoading) return false
      }

      if (!this.appService.connection.getConnection()) {
        this.messages.push({ sender: 'bot', text: this.appService.connection.noConnection(this.appService), isError: true });
        return;
      }

      this.messages.push({ sender: 'bot', text: 'PAGES.HOME.CHAT.LOADING', isLoading: true });
      this.appService.apiService.chatSearch({ token: this.userInput }).then((data: ResponseChat[]) => {
        console.log(data);
        this.messages.pop();
        this.messages.push({ sender: 'user', text: this.userInput });
        if (data) {
          if (data.length <= 0) {
            this.messages.push({ sender: 'bot', text: 'PAGES.HOME.CHAT.NOT_FOUND' });
          } else {
            data.forEach(item => {

              const urlParts = item.url.split("/");
              const formattedUrl = urlParts[urlParts.length - 2] + "/" + urlParts[urlParts.length - 1];

              this.messages.push({ sender: 'bot', text: item.titolo, img: item.img, url: formattedUrl });
            });
            console.log('this.messages: ', this.messages)
          }
        } else {
          this.messages.push({ sender: 'bot', text: 'PAGES.HOME.CHAT.NOT_FOUND' });
        }
        this.userInput = '';
      }).catch((err) => {
        this.messages.pop();
        this.messages.push({ sender: 'bot', text: err, isError: true });
        console.log('error: ', err);
      });
    }
  }

}
