import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AppService } from './AppService';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private appService: AppService) { }

  cantActivePrivatePage(): Promise<boolean> {
    return this.appService.start().then((res) => {
      if (this.appService.userAuth.isLogged()) {
        return true;
      } else {
        this.appService.viewLogin();
        return false;
      }
    });
  }

  cantActiveNotLoggedPage(): Promise<boolean> {
    return this.appService.start().then((res) => {
      if (this.appService.userAuth.isLogged()) {
        this.appService.viewHome();
        return false;
      } else {
        return true;
      }
    });
  }

  cantActivePublicPage(): Promise<boolean> {
    return this.appService.start().then((res) => {

      const startAPP = localStorage.getItem('startAPP');
      if (!startAPP) {
        localStorage.setItem('startAPP', new Date().toDateString());
        this.appService.viewTutorial();
      }

      return true;

    });
  }

}

export const IsLoggedAuthGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(AuthGuard).cantActivePrivatePage();
}

export const IsNotLoggedAuthGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(AuthGuard).cantActiveNotLoggedPage();
}

export const IsPublicPageAuthGuard: CanActivateFn = (): Promise<boolean> => {
  return inject(AuthGuard).cantActivePublicPage();
}