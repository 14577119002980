import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/providers/AppService';

declare var Mirador: any;

@Component({
  selector: 'app-mirador',
  templateUrl: './mirador.component.html',
  styleUrls: ['./mirador.component.scss']
})
export class MiradorComponent implements OnInit, OnDestroy {

  private miradorInstance: any = null;

  constructor(public appService: AppService) {

  }
  
  ngOnInit() {
    console.log('history.state:', history.state)
    if (history.state && history.state.link) {
      this.inizializeMirador(history.state.link);

      // Gestione degli eventi per il cambio modalità schermo intero
      document.addEventListener('fullscreenchange', (event) => {
        if (document.fullscreenElement) {
        } else {
          this.inizializeMirador(history.state.link);
        }
      });
    }
  }

  inizializeMirador(manifestId: string) {
    // Per vedere le altre modifiche che si possono aggiungere al viewer https://github.com/ProjectMirador/mirador/blob/master/src/config/settings.js
    this.miradorInstance = Mirador.viewer({
      id: "mirador-viewer",
      workspaceControlPanel: {
        enabled: false, // Configure if the control panel should be rendered.  Useful if you want to lock the viewer down to only the configured manifests
      },
      window: {
        allowWindowSideBar: false, // Configure if side bar menu is visible or not
        allowTopMenuButton: false, // Configure if window view and thumbnail display menu are visible or not
        allowClose: false, // Configure if windows can be closed or not
        allowMaximize: false, // Configure if windows can be maximized or not
        allowFullscreen: true, // Configure to show a "fullscreen" button in the WindowTopBar
        hideWindowTitle: false // Configure if the window title is shown in the window title bar or not
      },
      windows: [
        {
          manifestId
        }
      ],
      language: this.appService.actualLang,
      /*thumbnailNavigation: {
        defaultPosition: "far-bottom"
      }*/
    });
  }

  ngOnDestroy() {
    if(this.miradorInstance) {
      console.log('this.miradorInstance: ', this.miradorInstance.store.getState())
      this.miradorInstance = null;
    }
  }
}
