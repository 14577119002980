<!--PAGE-->
<section class="margin-content">
    <!--CONTAINER-->
    <div class="container-animated">
        <br>
        <div class="row">
            <div
                id="mirador-viewer"
                style="width: 100%; height: 500px; position: relative;"
            ></div>
        </div>
        <br><br><br><br>
    </div>
</section>