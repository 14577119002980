import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/providers/AppService';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-articolo',
  templateUrl: './articolo.component.html',
  styleUrls: ['./articolo.component.scss']
})
export class ArticoloComponent implements OnInit, OnDestroy {

  public error: string;
  public loading: boolean = true;
  public articolo: any;

  // IMAGES
  activeImageIndex = 0;
  showFullscreenGallery = false;
  activeFullscreenImageIndex = 0;
  fullscreenImages = [];
  
  constructor(public appService: AppService, private actRoute: ActivatedRoute, private http: HttpClient) {
    const id = this.actRoute.snapshot.params.id;
    this.getArticolo(id);
  }

  ngOnInit() {
    document.addEventListener('keydown', this.handleKeyboardEvent);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.handleKeyboardEvent);
  }

  handleKeyboardEvent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.showFullscreenGallery = false;
    }
  };

  getArticolo(id: string){
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if(!id) this.error = this.appService.translate.instant('PAGES.CATEGORY.ERRORS.ID_NOT_VALID');

    this.articolo = null;
    this.error = '';
    this.appService.apiService.getArticolo(Number(id)).then((data: any) => {
      if(data?.pubblicato) {
        this.articolo = data;
      }
      
      console.log('data: ', data);
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.loading = false);
  }

  downloadFile(allegato: any) {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    allegato.errorDownload = '';
    this.http.get(allegato.path, { responseType: 'blob' }).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      fileSaver.saveAs(blob, allegato.titolo);
    }, err => {
      console.log('errore scaricamento: ', err);
      allegato.errorDownload = this.appService.translate.instant('PAGES.ARTICOLI.ERRORS.FILE_NOT_FOUND')
    });
  }

  changeImage(step: number, articolo: any) {
    this.activeImageIndex = (this.activeImageIndex + step + articolo.mediaarticolos.length) % articolo.mediaarticolos.length;
    articolo.mediaarticolos.forEach((img, index) => img.active = index === this.activeImageIndex);
  }

  openFullscreenGallery(images: any[]) {
    this.fullscreenImages = images.map((img, index) => ({ ...img, active: index === 0 }));
    this.showFullscreenGallery = true;
    this.activeFullscreenImageIndex = 0;
  }

  changeFullscreenImage(step: number) {
    this.activeFullscreenImageIndex = (this.activeFullscreenImageIndex + step + this.fullscreenImages.length) % this.fullscreenImages.length;
    this.fullscreenImages.forEach((img, index) => img.active = index === this.activeFullscreenImageIndex);
  }

  setActiveImage(index: number, articolo: any) {
    this.activeImageIndex = index;
  }

  setActiveFullscreenImage(index: number) {
    this.activeFullscreenImageIndex = index;
  }
}
