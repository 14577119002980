import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Chat, LoginBody, SignupBody, UpdateUserBody} from '../models/interfaces';

const API_BASE_URL = 'https://app.tradizionifuture.it/api/customer/';
const API_ATOM_URL = '';

@Injectable()
export class ApiService {

    constructor(private http: HttpClient) {
    }

    authLogin(body: LoginBody) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}auth/login`;
            this.http.post(url, body).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    authSignup(body: SignupBody) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}auth/add`;
            this.http.post(url, body).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    recoverPassword(email: string) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}reset-password`;
            this.http.post(url, {email: email}).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    authUpdateUser(body: UpdateUserBody) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}auth/edit`;
            this.http.post(url, body).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    chatSearch(body: Chat) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}cerca`;
            this.http.post(url, body).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getCategoriePuntiDiInteresse() {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}poi/categorie`;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getDettagliCategoriePuntiDiInteresse(id: number) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}poi/categorie/dettagli/` + id;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getDettagliPuntoDiInteresse(id: number) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}poi/detail/` + id;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getItinerari() {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}itinerari`;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getItinerario(id: string) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}itinerari/` + id;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getArticoli(id: number) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}articoli/categorie/articoli/` + id;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getArticolo(id: any) {
        return new Promise((resolve, reject) => {
            const url = `${API_BASE_URL}articoli/detail/` + id;
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    searchAtom(term: string) {
        return new Promise((resolve, reject) => {
            term = term.trim();
            const url = `${API_BASE_URL}cerca-opere/` + encodeURIComponent(term);
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    getOperaAtom(slug: string) {
        return new Promise((resolve, reject) => {
            slug = slug.trim();
            const url = `${API_BASE_URL}opera/` + encodeURIComponent(slug);
            this.http.get(url).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    dwnloadOperaAtom(slug: string) {
        return new Promise((resolve, reject) => {
            slug = slug.trim();
            const url = `${API_BASE_URL}download/pdf/` + encodeURIComponent(slug);
            this.http.get(url, {responseType: 'arraybuffer'}).subscribe((data) => {
                resolve(data);
            }, (err) => {
                reject(this.formatError(err));
            });
        });
    }

    private formatError(err: any) {
        console.log('formatError:', err);
        if (err.error?.errors) {
            return this.processErrors(err.error?.errors);
        }

        return err?.error?.error ? err?.error?.error : err?.statusText;
    }

    private processErrors(errors: any): string {
        console.log('processErrors:', errors);
        let errorString = '';

        // Get the keys of the 'errors' object
        const errorKeys = Object.keys(errors);

        // Iterate over the keys
        errorKeys.forEach(key => {
            // Access the array for each key
            const errorArray = errors[key];

            // Iterate over the array and append to the error string
            errorArray.forEach(errorMessage => {
                errorString += `${key}: ${errorMessage}\n`;
            });
        });

        return errorString;
    }
}
