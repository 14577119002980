<!--PAGE-->
<section class="margin-content">


    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--EDIT ACCOUNT-->
    <h2 class="sub-header-title"><img src="assets/img/icona_articoli.png" style="width: 50px;">
        {{(categoryId == 1 ? 'PAGES.ARTICOLI.ARTICOLI' : 'PAGES.HOME.RICETTE')| translate}}</h2>

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

            <!--CARD 1-->
            <div *ngFor="let a of articoli;" class="col-lg-4 col-md-4 col-sm-12"
                [style]="a.pubblicato ? '' : 'display: none'">
                <div class="card">
                    <img [src]="a.pathCopertina" [alt]="a.titolo">
                    <div class="card-content">
                        {{ a.titolo }}
                        <br>
                        <button (click)="appService.viewArticolo(a.id)">{{ ('GENERAL.VIEW' | translate) |
                            uppercase}}</button>
                    </div>
                </div>
            </div>

            <!--NOT FOUND-->
            <div class="error-404-area" *ngIf="!loading && articoli?.length < 1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{ 'PAGES.ARTICOLI.ERRORS.ARTICOLI_NOT_FOUND' | translate }}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br><br>
    </div>

</section>
