import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  public form: FormGroup;
  public submitted: boolean = false;
  public sending: boolean = false;
  public error: string;

  constructor(private formBuilder: FormBuilder, public appService: AppService) { 
    
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      nome: [this.appService.userAuth.userData.nome, Validators.compose([Validators.required,
        Validators.maxLength(128)
      ])],
      cognome: [this.appService.userAuth.userData.cognome, Validators.compose([Validators.required,
        Validators.maxLength(128)
      ])],
      email: [this.appService.userAuth.userData.email, Validators.compose([
        Validators.required,
        Validators.maxLength(128),
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,4})$')
      ])],
      cellulare: [this.appService.userAuth.userData.cellulare, Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]*$')
      ])]
    });
  }

  update() {
    // this.appService.show('TEST')
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    this.submitted = true;
    if (this.form.valid) {
      const nome = this.form.controls['nome'].value;
      const cognome = this.form.controls['cognome'].value;
      const email = this.form.controls['email'].value;
      const cellulare = this.form.controls['cellulare'].value + '';

      console.log('nome: ' + nome + ' cognome' + cognome + ' email: ' + email  + ' cellulare: ' + cellulare)

      this.sending = true;
      this.appService.apiService.authUpdateUser({id: this.appService.userAuth.userData.id, nome, cognome, email, cellulare, idStatoCivile: this.appService.userAuth.userData.idStatoCivile}).then((data: User) => {
        console.log('update successful: ', data);
        this.appService.userAuth.setUpdatedData(data);
      }).catch((err) => {
        console.log('signup failed: ', err);
        this.error = err;
      }).finally(() => {
        this.submitted = false;
        this.sending = false;
      });
    }
  }
}
