import { Component } from '@angular/core';
import { Itinerari } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-itinerari',
  templateUrl: './itinerari.component.html',
  styleUrls: ['./itinerari.component.scss']
})
export class ItinerariComponent {

  public error: string;
  public loading: boolean = true;
  public itinerari: Itinerari[] = [];
  
  constructor(public appService: AppService,) {
    this.getItinerari();
  }

  getItinerari() {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }

    this.itinerari = [];
    this.error = '';

    this.appService.apiService.getItinerari().then((data: Itinerari[]) => {
      this.itinerari = data;
      console.log('data: ', data);
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.loading = false);
  }
}
