import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent {

  cameraEnabled = false;

  constructor(private router: Router) { }

  toggleCamera() {
    this.cameraEnabled = !this.cameraEnabled;
  }

  onCodeResult(result: string) {
    console.log('result: ', result)
    if(!result) return false;
    const rsJson = JSON.parse(result)
    console.log('rsJson: ', rsJson)
    if(rsJson && rsJson.url) {
      const decoded = decodeURIComponent(rsJson.url)
      console.log('decoded: ', decoded)
      const urlParts = decoded.split("/");
      console.log('urlParts: ', urlParts)
      const angularUrl = urlParts[urlParts.length - 2] + '/' + urlParts[urlParts.length - 1]
      console.log('angularUrl: ', angularUrl)
      this.router.navigateByUrl(angularUrl);
    }
    
  }

}
