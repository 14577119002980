<!--FOOTER-->
<footer class="footer hideFooterMobile">
    <a (click)="appService.viewProfile()" class="tab-button active">
        <i class='bx bx-user'></i>
        <span>{{'FOOTER.PROFILE'| translate}}</span>
    </a>
    <a (click)="appService.viewScanner()" class="tab-button">
        <i class='bx bx-scan'></i>
        <span>{{'FOOTER.SCAN'| translate}}</span>
    </a>
    <a (click)="appService.viewHome()" class="tab-button">
        <i class='bx bx-search'></i>
        <span>{{'FOOTER.SEARCH'| translate}}</span>
    </a>
    <a class="tab-button" (click)="appService.changeLang()">
        <img src="assets/img/lang/{{appService.actualLang}}.png">
        <span>{{ appService.actualLang | uppercase }}</span>
    </a>
</footer>