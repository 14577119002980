<!--PAGE-->
<section class="margin-content">

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row" align="center" style="margin-top: 20em;">

            <!--NOT FOUND-->
            <div class="error-404-area">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{'GENERAL.ERRORS.NOT_FOUND'| translate}}</h3>
                                <a routerLink="/" class="default-btn">{{'GENERAL.BACK_HOME'| translate}}</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>