import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() location: any;
  showShareMenu = false;

  constructor(public appService: AppService) { }

  ngOnInit() {
    
  }

  ngOnChanges() {
    console.log('location: ', this.location)
  }

  toggleShareMenu() {
    this.showShareMenu = !this.showShareMenu;
  }

}
