import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DettagliCategoriePuntiDiInteresse } from 'src/app/models/interfaces';
import { AppService } from 'src/app/providers/AppService';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {
  
  public error: string;
  public loading: boolean = true;
  public dataCategory: DettagliCategoriePuntiDiInteresse;

  constructor(public appService: AppService, private actRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      const categoryId = params.get('id');
      console.log('categoria: ', categoryId)
      this.getDataByCategory(categoryId);
    });
  }

  getDataByCategory(id: string){
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }
    if(!id) this.error = this.appService.translate.instant('PAGES.CATEGORY.ERRORS.ID_NOT_VALID');
    this.loading = true;
    this.dataCategory = null;
    this.error = '';
    this.appService.apiService.getDettagliCategoriePuntiDiInteresse(Number(id)).then((data: DettagliCategoriePuntiDiInteresse) => {
      console.log('data: ', data);
      if(data?.pubblicata) {
        this.dataCategory = data;
      }
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => this.loading = false);
  }
}
