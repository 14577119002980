<!--PAGE-->
<section class="margin-content">


    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <h2 class="sub-header-title"><i class='bx bx-pin'></i> {{'PAGES.ITINERARI.ITINERARI'| translate}}</h2>
    <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

            <!--CARD 1-->
            <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let i of itinerari;"
                [style]="i.pubblicata ? '' : 'display: none'">
                <a routerLink="/itinerario/{{i.id}}" class="card">
                    <div class="card-image">
                        <img [src]="i.pathCopertina" [alt]="i.titolo">
                    </div>
                    <div class="card-content">
                        <div class="card-title">{{ i.titolo }}</div>
                        <button>{{ ('GENERAL.VIEW' | translate) | uppercase}}</button>
                    </div>
                </a>
            </div>

            <!--NOT FOUND-->
            <div class="error-404-area" *ngIf="!loading && itinerari.length < 1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{'PAGES.ITINERARI.ERRORS.ITINERARI_NOT_FOUND' | translate}}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br><br>
    </div>
</section>