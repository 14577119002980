import { Injectable, VERSION } from "@angular/core";
import { BehaviorSubject, Observable, fromEvent, merge, of } from "rxjs";
import { map } from 'rxjs/operators';
import { AppService } from "./AppService";

@Injectable()
export class ConnectionService {
    private angularVersion = VERSION.major;

    public connetionstate: boolean;
    public online$: Observable<boolean>;
    private NetworkStatus: BehaviorSubject<boolean>;


    constructor() {
        this.online$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false))
        );

        this.networkStatus();
    }

    public networkStatus() {
        this.online$.subscribe(value => {
            console.log('Angular ' + this.angularVersion + ' - Network Online? ' + value);
            this.setIsOnline(value);
        });
    }

    async UpdateNetworkStatus(IsOnline: boolean) {
        console.log('Network ', (IsOnline === true ? 'Online' : 'Offline'));

        console.log('Sono: UpdateNetworkStatus');
        this.setIsOnline(IsOnline);

        this.NetworkStatus.next(IsOnline);
    }

    
    setIsOnline(isOnline: boolean) {
        console.log('prima: ' + this.connetionstate);
        if (isOnline) {
            this.connetionstate = true;
        } else {
            this.connetionstate = false;
        }

        console.log('dopo: ' + this.connetionstate);
    }

    getConnection() {
        return this.connetionstate;
    }

    noConnection(appService: AppService) {
        return appService.translate.instant('GENERAL.MUST_BE_CONNECTED')
    }
}