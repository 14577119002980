<!--HEADER-->
<div class="header-tutorial containerStep1Tutorial" style="height: 100vh;overflow: hidden;">
    <img src="assets/img/logo.png" alt="image">
    <br>
    <h2>{{'PAGES.HOME.HERO_TITLE'| translate}}</h2>

    <br>
    <img src="assets/img/tutorial3.png" class="tutorialIcon">
    <br>
    <h2>{{'PAGES.TUTORIAL.AVVIA_ITINERARI'| translate}}</h2>

    <a routerLink="/home" style=" margin-top: 20px;"><button type="submit" class="buttonLight"
            style="min-width: 200px;">{{'PAGES.TUTORIAL.CONCLUDI'| translate}}</button></a>
</div>
