import {Injectable} from "@angular/core";
declare var owa_cmds: Array<any>;

@Injectable()
export class OwaService
{
  public static emitEvent(eventDetails: Array<any>) {
    if (typeof owa_cmds === 'object') {
      owa_cmds.push(eventDetails);
    } else {
      console.warn("owa_cmds is not a function", typeof owa_cmds);
    }
  }
}