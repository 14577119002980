<!--PAGE-->
<section class="margin-content">

    <div class="scanner-area container-animated">
        <div class="scanner-wrapper">
            <div *ngIf="!cameraEnabled">
                <i class='bx bx-scan' style="font-size: 10em;"></i>
            </div>
            <h4 *ngIf="!cameraEnabled">{{'PAGES.SCAN.SCAN_TEXT'| translate}} </h4>

            <zxing-scanner *ngIf="cameraEnabled" (scanSuccess)="onCodeResult($event)"></zxing-scanner>

            <br>

            <button class="toggle-button" (click)="toggleCamera()">
                {{ ((cameraEnabled ? 'PAGES.SCAN.DISABLE' : 'PAGES.SCAN.ENABLE') | translate)}}
            </button>
        </div>
    </div>



</section>