import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/providers/AppService';
import { Itinerari } from 'src/app/models/interfaces';
import { ActivatedRoute } from '@angular/router';
declare var L: any;

@Component({
  selector: 'app-itinerario',
  templateUrl: './itinerario.component.html',
  styleUrls: ['./itinerario.component.scss']
})
export class ItinerarioComponent implements OnInit {

  
  private map: any;
  private icon = {
    greenIcon: null,
    redIcon: null,
    yellowIcon: null,
    bluIcon: null,
    purpleIcon: null
  }
  public icons = ['color-rosso', 'color-giallo', 'color-verde', 'color-blu', 'color-viola'];

  public itinerario: Itinerari;
  public error: string;
  public loading: boolean = true;

  constructor(public appService: AppService, private actRoute: ActivatedRoute) {
    const id = decodeURIComponent(this.actRoute.snapshot.params.id);
    this.getItinerario(id);
  }

  ngOnInit(): void {
    
  }

  private initMap(): void {
    const lat = this.itinerario?.puntointeresses?.length > 0 ? this.itinerario?.puntointeresses[0].lat : 40.84327080655417
    const lng = this.itinerario?.puntointeresses?.length > 0 ? this.itinerario?.puntointeresses[0].lng : 14.251053552228864
    this.map = L.map('mappa').setView([lat, lng], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19
    }).addTo(this.map);

    var LeafIcon = L.Icon.extend({
      options: {
          iconSize:     [48, 48],
          iconAnchor:   [22, 94],
          popupAnchor:  [-3, -76]
      }
  } );
    this.icon.greenIcon = new LeafIcon({iconUrl: 'assets/img/itinerari/pin-verde.png'}),
    this.icon.redIcon = new LeafIcon({iconUrl: 'assets/img/itinerari/pin-rosso.png'}),
    this.icon.yellowIcon = new LeafIcon({iconUrl: 'assets/img/itinerari/pin-giallo.png'}),
    this.icon.bluIcon = new LeafIcon({iconUrl: 'assets/img/itinerari/pin-blu.png'}),
    this.icon.purpleIcon = new LeafIcon({iconUrl: 'assets/img/itinerari/pin-viola.png'});

    this.setLocations();
  }

  setLocations() {
    // se cambi qui cambiare anche public icons =
    const imgs = [this.icon.redIcon, this.icon.yellowIcon, this.icon.greenIcon, this.icon.bluIcon, this.icon.purpleIcon];

    /*this.itinerari.forEach((itinerario, index) => {
      itinerario.puntointeresses.forEach(puntoI => {
        new L.marker([puntoI.lat, puntoI.lng], {icon: imgs[index]})
        .bindPopup(`<b>${puntoI.titolo}</b> <br>${puntoI.indirizzo}<br>${puntoI.telefono} <br> <a href="https://www.google.com/maps/dir//${puntoI.lat},${puntoI.lng}" target="_blank">${this.appService.translate.instant('PAGES.ITINERARI.MAP.REACH_US')}</a>`)
        .addTo(this.map);
        });
    });*/
    this.itinerario?.puntointeresses?.forEach(puntoI => {
      if(puntoI.pubblicato) {
        new L.marker([puntoI.lat, puntoI.lng], {icon: imgs[0]})
        .bindPopup(`<b>${puntoI.titolo}</b> <br>${puntoI.indirizzo}<br>${puntoI.telefono ? puntoI.telefono : ''} <br> <a href="https://www.google.com/maps/dir//${puntoI.lat},${puntoI.lng}" target="_blank">${this.appService.translate.instant('PAGES.ITINERARI.MAP.REACH_US')}</a>`)
        .addTo(this.map); 
      }
    });
    /*var locations = [
      ["<b>Chiesda di Santa Sofia</b> <br> Piazza Giacomo Matteotti, 1, 80014 Giugliano in Campania NA <br> 081 895 6001", 40.84327080655417, 14.251053552228864, this.icon.redIcon],
      ["LOCATION_2", 40.84327080655417, 14.271053552228864, this.icon.yellowIcon],
      ["LOCATION_3", 40.85327080655417, 14.251053552228864, this.icon.greenIcon],
      ["LOCATION_4", 40.85327080655417, 14.231053552228864, this.icon.bluIcon],
      ["LOCATION_5", 40.84327080655417, 14.231053552228864, this.icon.purpleIcon]
    ];
    for (var i = 0; i < locations.length; i++) {
       new L.marker([locations[i][1], locations[i][2]], {icon: locations[i][3]})
        .bindPopup(locations[i][0])
        .addTo(this.map);
    }*/
  }
  
  getItinerario(id: string) {
    if (!this.appService.connection.getConnection()) {
      this.error = this.appService.connection.noConnection(this.appService);
      return;
    }

    this.itinerario = null;
    this.error = '';

    this.appService.apiService.getItinerario(id).then((data: Itinerari) => {
      if(data?.titolo && data?.pubblicata) {
        this.itinerario = data;
        console.log('data: ', data);
      }
      
    }).catch(err => {
      console.log('error: ', err);
      this.error = err;
    }).finally(() => { 
      this.loading = false;
      if(this.itinerario) { 
        setTimeout(() => {
          this.initMap(); 
        }, 100);
        
      }
    });
  }
}
