<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--EDIT ACCOUNT-->
    <h2 class="sub-header-title">
        {{ puntoDiInteresse?.titolo }}

        <!--ELEMENTI INFORMATIVI-->
        <div *ngIf="
        puntoDiInteresse?.minUmidita ||
        puntoDiInteresse?.maxUmidita ||
        puntoDiInteresse?.minVento ||
        puntoDiInteresse?.maxVento ||
        puntoDiInteresse?.minTemperatura ||
        puntoDiInteresse?.maxTemperatura ||
        puntoDiInteresse?.theta ||
        puntoDiInteresse?.phi
        " class="info-container" style="max-width: 1200px; margin: auto;margin-top: 20px;">

            <div *ngIf="puntoDiInteresse?.minUmidita || puntoDiInteresse?.maxUmidita" class="info-item">
                <span class="info-title">{{ 'PAGES.OPERE.UMIDITA' | translate }}</span>
                <span class="info-value">{{ puntoDiInteresse?.minUmidita | number : '1.2-2' }} - {{
                    puntoDiInteresse?.maxUmidita | number : '1.2-2' }}</span>
            </div>

            <div *ngIf="puntoDiInteresse?.minVento || puntoDiInteresse?.maxVento" class="info-item">
                <span class="info-title">{{ 'PAGES.OPERE.VENTO' | translate }}</span>
                <span class="info-value">{{ puntoDiInteresse?.minVento | number : '1.2-2' }} - {{
                    puntoDiInteresse?.maxVento | number : '1.2-2' }}</span>
            </div>

            <div *ngIf="puntoDiInteresse?.minTemperatura || puntoDiInteresse?.maxTemperatura" class="info-item">
                <span class="info-title">{{ 'PAGES.OPERE.TEMPERATURA' | translate }}</span>
                <span class="info-value">{{ puntoDiInteresse?.minTemperatura | number : '1.2-2' }} &#8451; - {{
                    puntoDiInteresse?.maxTemperatura | number : '1.2-2' }} &#8451;</span>
            </div>


            <div *ngIf="puntoDiInteresse?.phi" class="info-item">
                <span class="info-title">Phi:</span>
                <div class="progress-container">
                    <div class="progress-min-max">
                        <div class="progress-min-max-label">-180</div>
                        <div class="progress-min-max-label">180</div>
                    </div>
                    <mat-progress-bar mode="determinate"
                        [value]="convertToPercentage(puntoDiInteresse?.phi)"></mat-progress-bar>
                    <div class="progress-label">({{ puntoDiInteresse?.phi }})</div>
                </div>
            </div>

            <div *ngIf="puntoDiInteresse?.theta" class="info-item">
                <span class="info-title">Theta:</span>
                <div class="progress-container">
                    <div class="progress-min-max">
                        <div class="progress-min-max-label">-180</div>
                        <div class="progress-min-max-label">180</div>
                    </div>
                    <mat-progress-bar mode="determinate"
                        [value]="convertToPercentage(puntoDiInteresse?.theta)"></mat-progress-bar>
                    <div class="progress-label">({{ puntoDiInteresse?.theta }})</div>
                </div>
            </div>

        </div>

        <div class="container-animated" *ngIf="puntoDiInteresse?.contenuto">
            <div class="card" style="padding: 15px;">
                
                <div class="spinner-container" *ngIf="spinnerTranslate">
                    <div class="spinner"></div>
                </div>
                <div *ngIf="!spinnerTranslate" [innerHTML]="translatedText" style="font-size: 16px;text-transform: none!important;">
                </div>
            </div>
        </div>

    </h2>

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>




            <!--CARD 1-->
            <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let o of puntoDiInteresse?.operapuntointeresses;">
                <a routerLink="/opera/{{o.slug}}" class="card">
                    <div class="card-image">
                        <img *ngIf="o.thumbnail" [src]="o.thumbnail" [alt]="o.titolo">
                    </div>
                    <div class="card-content">
                        <div class="card-title">{{ o.titolo }}</div>
                        <div class="card-title"> </div>
                        <button>{{ ('GENERAL.VIEW' | translate) | uppercase}}</button>
                    </div>
                </a>
            </div>

            <!--NOT FOUND
            <div class="error-404-area" *ngIf="!loading && puntoDiInteresse?.operapuntointeresses?.length < 1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{ 'GENERAL.OPERE_NOT_FOUND' | translate }}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>-->

        </div>
        <br><br><br><br>
    </div>

</section>