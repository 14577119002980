import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OperaAtom} from 'src/app/models/interfaces';
import {AppService} from 'src/app/providers/AppService';

@Component({
    selector: 'app-ricerca',
    templateUrl: './ricerca.component.html',
    styleUrls: ['./ricerca.component.scss']
})
export class RicercaComponent {

    public title: string;
    public opere: OperaAtom[] = [];
    public error: string;
    public loading = true;

    constructor(public appService: AppService, private actRoute: ActivatedRoute) {
        this.title = decodeURIComponent(this.actRoute.snapshot.params.title);
        this.search();
    }

    search(): void {
        if (!this.appService.connection.getConnection()) {
            this.error = this.appService.connection.noConnection(this.appService);
            return;
        }
        if (this.title == null || this.title == undefined) {
            return;
        }
        if (this.title.trim() == '') {
            return;
        }
        // this.opere = null;
        this.error = '';
        this.appService.apiService.searchAtom(this.title).then((data: OperaAtom[]) => {
            this.opere = data;
            console.log('data: ', data);
        }).catch(err => {
            // console.log('error: ', err);
            // this.error = err;
        }).finally(() => this.loading = false);
    }
}
