<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--CONTAINER-->
    <div class="container-animated">

        <!--SEARCH-->
        <h2 class="sub-header-title">
            {{ title }}
        </h2>

        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

            <!--CARD 1-->
            <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let o of opere;">
                <a routerLink="/opera/{{o.slug}}" class="card">
                    <div class="card-image">
                        <img [src]="o.thumbnail_url" [alt]="o.title">
                    </div>
                    <div class="card-content">
                        <div class="card-title">{{ o.title }}</div>
                        <button>{{ ('GENERAL.VIEW' | translate) | uppercase}}</button>
                    </div>
                </a>
            </div>

            <!--NOT FOUND-->
            <div class="error-404-area" *ngIf="!loading && opere.length < 1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{ 'GENERAL.OPERE_NOT_FOUND' | translate }}</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br><br>
    </div>
</section>