<!--PAGE-->
<section class=" margin-content">

    <!--CONTAINER-->
    <div class="container-animated">

        <h2 class="sub-header-title">{{ ('PAGES.RECOVER_PASSWORD.BTN_RECOVER' | translate) | uppercase }}</h2>
        <form *ngIf="form" [formGroup]="form" class="form" (ngSubmit)="passwordRecover()"
            (keyup.enter)="passwordRecover()">
            <label for="email">{{ ('PAGES.LOGIN.EMAIL' | translate) | uppercase }}</label>
            <input type="email" id="email" formControlName="email" placeholder="user@gmail.com">
            <span *ngIf="form.controls.email.hasError('pattern')"
                class="form-error-msg">{{'GENERAL_ACCOUNT.ERRORS.EMAIL_NOT_VALID' | translate}}</span>

            <button type="submit" [disabled]="sending">{{ ((sending ? 'GENERAL.LOADING' :
                'PAGES.RECOVER_PASSWORD.BTN_RECOVER') | translate) | capitalize }}</button>
            <span *ngIf="error" class="form-error-msg"><br>{{ error | translate}}</span>
            <span *ngIf="success" class="form-success-msg"><br>{{ success | translate}}</span>
        </form>
    </div>

</section>