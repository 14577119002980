<!--PAGE-->
<section class="margin-content">

    <!--SPINNER FULL-->
    <div class="spinnerFull-container" *ngIf="loading">
        <div class="spinnerFull"></div>
    </div>

    <!--EDIT ACCOUNT-->
    <h2 class="sub-header-title">
        <span *ngIf="!loading && dataCategory"><i class='bx bx-building-house'></i> {{
            dataCategory.nome }}</span>
    </h2>

    <!--CONTAINER-->
    <div class="container-animated">
        <div class="row">
            <span *ngIf="error" class="form-error-msg"><br>{{error}}</span>

            <!--CARD 1-->
            <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let puntoDiInteresse of dataCategory?.puntointeresses;"
                [style]="puntoDiInteresse.pubblicato ? '' : 'display: none'">
                <a routerLink="/opere/{{puntoDiInteresse.id}}" class="card">
                    <div class="card-image">
                        <img [src]="puntoDiInteresse.pathCopertina" [alt]="puntoDiInteresse.titolo">
                    </div>
                    <div class="card-content">
                        <div class="card-title">{{ puntoDiInteresse.titolo }}</div>
                        <button>{{ ('GENERAL.VIEW' | translate) | uppercase}}</button>
                    </div>
                </a>
            </div>

            <!--NOT FOUND-->
            <div class="error-404-area" *ngIf="!loading && !dataCategory">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="notfound">

                                <h1>Oops!</h1>
                                <h3>{{ 'PAGES.CATEGORY.ERRORS.DETAILS_NOT_FOUND' | translate }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br><br>
    </div>

</section>